import { type NextAuthOptions, type User } from "next-auth"
import { type User as JWTUser } from "next-auth/jwt"
import CredentialsProvider from "next-auth/providers/credentials"

import { APIRoutes } from "@/api/routes"
import { AUTH_TOKEN_HEADER } from "@/constants"
import { type UserData } from "@/types/User"
import { getHeaders } from "@/utils/request/utils"

const request = async (route: string, method: string, body: object) =>
  fetch(route, {
    method,
    body: JSON.stringify(body),
    headers: getHeaders(),
  })

const signInParams = {
  auth_source: "web",
}

interface SignInUserParams {
  email: string
  password: string
}

const signInUserAPI = (params: SignInUserParams) =>
  request(APIRoutes.SignInUser, "POST", {
    ...params,
    ...signInParams,
  })

interface GoogleSignInParams {
  credential: string
  selectBy: string
  clientID: string
}

const googleSignInAPI = (params: GoogleSignInParams) =>
  request(APIRoutes.GoogleAuth, "POST", {
    credential: params.credential,
    select_by: params.selectBy,
    client_id: params.clientID,
    ...signInParams,
  })

interface GitHubSignInParams {
  callbackCode: string
}

const gitHubSignInAPI = (params: GitHubSignInParams) =>
  request(APIRoutes.GitHubAuth, "POST", {
    callback_code: params.callbackCode,
    ...signInParams,
  })

/**
 * Authorize functionality used across all credentials providers
 */
const authorize = async <Credentials extends object>(
  credentials: Credentials,
  callback: (credentials: Credentials) => ReturnType<typeof request>,
) => {
  if (!credentials) {
    throw new Error("No credentials")
  }

  const response = await callback(credentials)
  const responseBody = (await response.json()) as UserData

  if (!response.ok) {
    throw new Error(`Error whilst authenticating: ${response.statusText}`)
  }

  const authToken = response
    ? response.headers.get(AUTH_TOKEN_HEADER)
    : undefined

  return {
    ...responseBody,
    authToken,
  }
}

export const AUTH_OPTIONS: NextAuthOptions = {
  providers: [
    // Email/password
    CredentialsProvider({
      id: "email-password",
      name: "Email & Password",
      credentials: {
        email: { label: "Email", type: "email" },
        password: { label: "Password", type: "password" },
      },
      authorize: (credentials) =>
        authorize(credentials!, signInUserAPI) as Promise<User | null>,
    }),
    // Google
    CredentialsProvider({
      id: "google",
      name: "Google",
      credentials: {
        credential: { label: "Credential", type: "password" },
        selectBy: { label: "Select By", type: "string" },
        clientID: { label: "Client ID", type: "string" },
      },
      authorize: async (credentials) =>
        authorize(credentials!, googleSignInAPI) as Promise<User | null>,
    }),
    // GitHub
    CredentialsProvider({
      id: "github",
      name: "GitHub",
      credentials: {
        callbackCode: { label: "Callback Code", type: "password" },
      },
      authorize: async (credentials) =>
        authorize(credentials!, gitHubSignInAPI) as Promise<User | null>,
    }),
  ],
  callbacks: {
    async jwt(jwt) {
      const { token, user, trigger, session } = jwt
      const newToken = { ...token }

      if (user) {
        newToken.user = user as JWTUser
      }

      // Handle updating a session with `session.update()`:
      if (trigger === "update" && session.user) {
        newToken.user = {
          ...newToken.user,
          ...session.user,
        }
      }

      return newToken
    },
    async session(obj) {
      const { session, token } = obj
      const newSession = { ...session }

      if (token?.user) {
        newSession.user = token.user
      }

      return newSession
    },
  },
}
