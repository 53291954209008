export const PHIDATA_API_URL = process.env.NEXT_PUBLIC_PHIDATA_API_URL

export const APIRoutes = {
  ReadUser: `${PHIDATA_API_URL}/v1/user/read`,
  UpdateUser: `${PHIDATA_API_URL}/v1/user/update`,
  AuthenticateUser: `${PHIDATA_API_URL}/v1/user/authenticate`,

  SignInUser: `${PHIDATA_API_URL}/v1/user/signin`,
  SignOutUser: `${PHIDATA_API_URL}/v1/user/signout`,
  GoogleAuth: `${PHIDATA_API_URL}/v1/user/googleauth`,
  GitHubAuth: `${PHIDATA_API_URL}/v1/user/githubauth`,

  ReadUserPlan: `${PHIDATA_API_URL}/v1/user/plan/read`,
  UpsertUserPlan: `${PHIDATA_API_URL}/v1/user/plan/upsert`,

  ReadTeamPlan: `${PHIDATA_API_URL}/v1/team/plan/read`,
  UpsertTeamPlan: `${PHIDATA_API_URL}/v1/team/plan/upsert`,

  ReadAllTeamUsers: `${PHIDATA_API_URL}/v1/team/user/read/all`,
  ReadAllTeamMembers: `${PHIDATA_API_URL}/v1/team/member/read/all`,
  AddTeamMember: `${PHIDATA_API_URL}/v1/team/member/add`,
  DeleteTeam: `${PHIDATA_API_URL}/v1/team/delete`,
  UpdateTeamMember: `${PHIDATA_API_URL}/v1/team/member/update`,
  DeleteTeamMember: `${PHIDATA_API_URL}/v1/team/member/delete`,
  JoinTeamMember: `${PHIDATA_API_URL}/v1/team/member/join`,

  UpdateTeamUser: `${PHIDATA_API_URL}/v1/team/user/update`,
  DeleteTeamUser: `${PHIDATA_API_URL}/v1/team/user/delete`,

  CreateCustomer: `${PHIDATA_API_URL}/v1/customer/create`,
  ReadCustomer: `${PHIDATA_API_URL}/v1/customer/read`,
  UpdateCustomer: `${PHIDATA_API_URL}/v1/customer/update`,

  ReadPrimaryWorkspace: `${PHIDATA_API_URL}/v1/workspace/read/primary`,
  UpdatePrimaryWorkspace: `${PHIDATA_API_URL}/v1/workspace/update/primary`,
  ReadAllWorkspaces: `${PHIDATA_API_URL}/v1/workspace/read/all`,
  UpdateWorkspace: `${PHIDATA_API_URL}/v1/workspace/update`,
  DeleteWorkspace: `${PHIDATA_API_URL}/v1/workspace/delete`,

  GetUserActivity: `${PHIDATA_API_URL}/v1/workspace/event/read`,
  GetWorkspaceActivity: `${PHIDATA_API_URL}/v1/workspace/event/read`,

  CreateTeam: `${PHIDATA_API_URL}/v1/team/create`,
  ReadAllTeams: `${PHIDATA_API_URL}/v1/team/read/all`,
  UpdateTeam: `${PHIDATA_API_URL}/v1/team/update`,

  GetAllTeamWorkspaces: `${PHIDATA_API_URL}/v1/team/workspace/get/all`,
  TeamReadAllWorkspaces: `${PHIDATA_API_URL}/v1/team/workspace/read/all`,
  MoveWsFromUserToTeam: `${PHIDATA_API_URL}/v1/team/workspace/movefromuser`,
  MoveWsFromTeamToTeam: `${PHIDATA_API_URL}/v1/team/workspace/movefromteam`,

  ReadAgentSessions: `${PHIDATA_API_URL}/v1/agent/session/read/all`,
  ReadAgentSession: `${PHIDATA_API_URL}/v1/agent/session/read`,
  UpdateAgentSession: `${PHIDATA_API_URL}/v1/agent/session/update`,
  GetAgentSessionCount: `${PHIDATA_API_URL}/v1/agent/session/count`,

  ReadAgentRuns: `${PHIDATA_API_URL}/v1/agent/run/read`,
  UpdateAgentRun: `${PHIDATA_API_URL}/v1/agent/run/update`,
  CreateAgentRun: `${PHIDATA_API_URL}/v1/agent/run/create`,

  ReadDatasets: `${PHIDATA_API_URL}/v1/dataset/read`,
  CreateDataset: `${PHIDATA_API_URL}/v1/dataset/create`,
  AddConversationToDataset: `${PHIDATA_API_URL}/v1/dataset/conversation/create`,

  ReadAllMonitors: `${PHIDATA_API_URL}/v1/monitor/all`,
  ReadMonitorRuns: `${PHIDATA_API_URL}/v1/monitor/runs`,
  ReadMonitorTokens: `${PHIDATA_API_URL}/v1/monitor/tokens`,
  ReadMonitorUsers: `${PHIDATA_API_URL}/v1/monitor/users`,
  ReadMonitorFeedback: `${PHIDATA_API_URL}/v1/monitor/feedback`,
  ReadMonitorHallucinations: `${PHIDATA_API_URL}/v1/monitor/hallucinations`,

  ReadDashboardSessions: `${PHIDATA_API_URL}/v1/dashboard/sessions`,
  ReadDashboardTokens: `${PHIDATA_API_URL}/v1/dashboard/tokens`,
  ReadDashboardUsers: `${PHIDATA_API_URL}/v1/dashboard/users`,
  ReadDashboardRuns: `${PHIDATA_API_URL}/v1/dashboard/runs`,

  CreateCheckoutSession: `${PHIDATA_API_URL}/v1/checkout/create`,
  CreateCustomerPortalSession: `${PHIDATA_API_URL}/v1/checkout/customer-portal-session`,
  CustomerSubscriptionDetails: `${PHIDATA_API_URL}/v1/checkout/customer-subscription-details`,

  AddToWaitlist: `${PHIDATA_API_URL}/v1/user/waitlist`,
  WaitlistCount: `${PHIDATA_API_URL}/v1/user/waitlist-count`,

  ReadAllFeatureRequests: `${PHIDATA_API_URL}/v1/feature-requests/read`,
  CreateFeatureRequest: `${PHIDATA_API_URL}/v1/feature-requests/create`,

  GetUserKey: `${PHIDATA_API_URL}/v1/user-keys/read`,
  RefreshUserKey: `${PHIDATA_API_URL}/v1/user-keys/refresh`,

  GetTeamMemberInvite: `${PHIDATA_API_URL}/v1/team/member/get-invite`,
}
