import * as Sentry from "@sentry/nextjs"

import { APIError } from "@/api/errors/APIError"
import { ALL_FIELDS_KEY, FormError } from "@/api/errors/FormError"
import { AUTH_OPTIONS } from "@/app/api/auth/[...nextauth]/config"

import type { ErrorResponseBody, RequestMethod, RequestOptions } from "./types"

import { getRequest, getResponseBody } from "./utils"

export const request = async <Response extends object | number | string>(
  route: string,
  method: RequestMethod,
  options?: RequestOptions,
) => {
  const response = await getRequest(route, method, AUTH_OPTIONS, options)
  const responseBody = await getResponseBody(response)

  if (!response.ok) {
    const context = {
      request: {
        route,
        method,
        options,
      },
      response,
      responseBody,
    }

    Sentry.addBreadcrumb({
      category: "api",
      message: `Request: ${method} ${route}`,
      level: "error",
      data: context,
    })

    if (response.status === 400) {
      // TODO: update this once the API form validation has been updated globally on the back-end (PHI-380):
      throw new FormError(response.statusText, response.status, {
        [ALL_FIELDS_KEY]: [
          (responseBody as unknown as ErrorResponseBody)?.detail,
        ],
      })
    } else {
      throw new APIError(response.statusText, response.status, context)
    }
  }

  return {
    body: responseBody as Response,
    headers: response.headers,
    status: response.status,
  }
}
