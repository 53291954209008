import { type NextAuthOptions, getServerSession, type Session } from "next-auth"
import { type JWT } from "next-auth/jwt"
import { getSession } from "next-auth/react"

import { AUTH_TOKEN_HEADER, IS_BROWSER } from "@/constants"

import type { RequestMethod, RequestOptions } from "./types"
import type { CustomUser } from "@/types/assets/next-auth"

import { debugLog } from "../log"
import { omitDeepBy } from "../misc"

const getUser = async (authOptions?: NextAuthOptions, token?: JWT) => {
  let clientSession: Session | null = null
  let serverSession: Session | null = null

  if (IS_BROWSER) {
    clientSession = await getSession()
  } else {
    serverSession = await getServerSession(authOptions ?? {})
  }

  const user = clientSession?.user ?? serverSession?.user ?? token?.user

  return user
}

const getUserParams = (user: CustomUser) => ({
  user: { id_user: user.id_user, email: user.email },
})

const getRequestBody = (
  method: RequestMethod,
  body: RequestOptions["body"],
  format: RequestOptions["format"],
  user?: CustomUser,
  options?: RequestOptions,
) => {
  const canHaveBody = ["GET", "HEAD"].includes(method) === false
  let requestBody: object | undefined

  if (canHaveBody) {
    requestBody = body ?? {}
    const formattedBody = format ? format(requestBody) : requestBody
    const redactedBody = omitDeepBy(
      formattedBody,
      (value) => value === undefined || value === null,
    )

    requestBody = {
      ...redactedBody,
    }
  }

  if (user && canHaveBody) {
    requestBody = {
      ...(requestBody ?? {}),
      ...(user && options?.includeUserParams ? getUserParams(user) : {}),
    }
  }

  return requestBody ? JSON.stringify(requestBody) : undefined
}

export const getHeaders = (user?: CustomUser) =>
  ({
    "Content-Type": "application/json",
    [AUTH_TOKEN_HEADER]: user?.authToken ?? undefined,
  }) as HeadersInit

export const getRequest = async (
  route: string,
  method: RequestMethod,
  authOptions?: NextAuthOptions,
  options?: RequestOptions,
) => {
  const user = await getUser(authOptions, options?.token)
  let formattedRoute = route

  if (options?.queryParam) {
    const newUrl = new URL(route)
    Object.entries(options.queryParam).forEach(([key, value]) => {
      newUrl.searchParams.append(key, value)
    })
    formattedRoute = newUrl.toString()
  }
  const requestBody = getRequestBody(
    method,
    options?.body,
    options?.format,
    user,
    options,
  )

  const params = {
    method,
    headers: getHeaders(user),
    body: requestBody,
    cache: options?.cache,
    next: {
      tags: options?.tags,
      revalidate: options?.revalidate,
    },
  }

  debugLog(
    "route",
    route,
    "method",
    method,
    "request body",
    requestBody,
    "params",
    params,
  )

  return fetch(formattedRoute, params)
}

export const getResponseBody = async (
  response: Response,
  options?: RequestOptions,
) => {
  let responseBody = {}

  if (response.ok) {
    try {
      responseBody = (await response.json()) as object
    } catch (e) {
      // Pass
    }
  }

  if (options?.format) {
    responseBody = options.format(responseBody)
  }

  return responseBody as Response
}
