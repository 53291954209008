import { type DefaultPageParams } from "./types/globals"

const getWorkspaceRoot = (params: DefaultPageParams) =>
  `/${params.account}/${params.workspace}`

const getTeamRoot = (params: DefaultPageParams) => `/${params.account}`

export const USER_ROUTES = {
  UserDashboard: "/",
  UserActivity: "/activity",
  UserDatasets: "/datasets",
  UserSessions: "/sessions",
  UserSession: (ID: string) => `/sessions/${ID}`,
  UserEvaluations: "/evaluations",
  UserPrompts: "/prompts",
  UserPlayground: "/playground",
  UserHumanReview: "/human-review",
  UserSettings: "/settings",
  UserBillingSettings: "/settings/billing",
  UserTeamSettings: "/settings/teams",
  TeamBilling: "/settings/teams/billing",
}

export const WORKSPACE_ROUTES = {
  WorkspaceDashboard: getWorkspaceRoot,
  WorkspaceSessions: (params: DefaultPageParams) =>
    `${getWorkspaceRoot(params)}/sessions`,
  WorkspaceSession: (params: DefaultPageParams) =>
    `${getWorkspaceRoot(params)}/sessions/${params.id}`,
  WorkspaceEvaluations: (params: DefaultPageParams) =>
    `${getWorkspaceRoot(params)}/evaluations`,
  WorkspacePlayground: (params: DefaultPageParams) =>
    `${getWorkspaceRoot(params)}/playground`,
  WorkspaceSettings: (params: DefaultPageParams) =>
    `${getWorkspaceRoot(params)}/settings`,
  WorkspaceActivity: (params: DefaultPageParams) =>
    `${getWorkspaceRoot(params)}/activity`,
}

export const TEAM_ROUTES = {
  TeamDashboard: getTeamRoot,
  TeamActivity: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/activity`,
  TeamEvaluations: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/evaluations`,
  TeamPrompts: (params: DefaultPageParams) => `${getTeamRoot(params)}/prompts`,
  TeamHumanReview: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/human-review`,
  TeamSessions: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/sessions`,
  TeamSession: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/sessions/${params.id}`,
  TeamDatasets: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/datasets`,
  TeamPlayground: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/playground`,
  TeamSettings: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/settings`,
  TeamMemberSettings: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/settings/members`,
  TeamBillingSettings: (params: DefaultPageParams) =>
    `${getTeamRoot(params)}/settings/billing`,
}

export const MAIN_APP_ROUTES = {
  ...USER_ROUTES,
  ...WORKSPACE_ROUTES,
  ...TEAM_ROUTES,
}

export const UNAUTHENTICATED_ROUTES = {
  // Auth
  SignIn: "/login",
  ForgotPassword: "/forgot-password",
  Waitlist: "/waitlist",
  // Legal
  TermsOfService: "/terms-of-service",
  PrivacyPolicy: "/privacy-policy",
}

export const ROUTES = {
  ...MAIN_APP_ROUTES,
  ...UNAUTHENTICATED_ROUTES,
  // Social and other links
  Docs: "https://docs.phidata.com",
  Discord: "https://discord.gg/4MtYHHrgA8",
  GitHub: "https://github.com/phidatahq/phidata",
  Twitter: "https://twitter.com/phidatahq",
}
